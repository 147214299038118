<template>
	<main class="page-container">
		<section class="support-section">
			<div class="container">
				<div class="support-section-header">
					<h2 class="support-section-title">소상공인 지원사업</h2>
				</div>
				<div class="support-section-body">
					<!-- 지원사업 선택 -->
					<div class="fp-biz-buttons">
						<button type="button" class="fp-biz-button" :class="{ 'is-active': showBizAside }" @click="showBiz()">중소벤처기업부 판로 지원 사업</button>
						<button type="button" class="fp-biz-button" :class="{ 'is-active': !showBizAside }" @click="searchAreaSupt()">지자체 판로 지원 사업</button>
					</div>
					<!-- s: 중소벤처기업부 판로 지원 사업 -->
					<div v-if="showBizAside" class="support-section-biz">
						<div class="support-section-biz__form">
								<fieldset>
									<legend class="sr-only">상세 검색</legend>
									<div class="form-row">
										<div class="control flex-1">
                      <div class="checkbox-list">
                        <div class="check">
                          <input type="checkbox" value="all" id="chkMssAll" v-model="allMssSelected" v-on:change="searchMssSprt"/>
                          <label for="chkMssAll">전체</label>
                        </div>
                        <div class="check" v-for="(ctgr, idx) in panpanCtgrList" :key="idx">
                          <input type="checkbox" :id="ctgr" :value="ctgr" v-model="mssSelectList" v-on:change="searchMssSprt"/>
                          <label :for="ctgr" :key="idx + '1'"> {{ ctgr }}</label>
                        </div>
                      </div>
											<div class="control-nums">
												{{ mssArea }}
												<strong>{{ numberComma(totalCount) }}</strong>
												<span class="unit">건</span>
											</div>
										</div>
									</div>
								</fieldset>
						</div>
						<ul v-if="mssSprtBizList.length > 0">
							<li v-for="(mssSprtBiz, idx) in mssSprtBizList" :key="idx">
								<!-- 지원사업 아티클 -->
								<article class="sptpgm-article">
									<header class="sptpgm-article-header">
										<span class="sptpgm-article-header__tag">{{ mssSprtBiz.sprtBizTyNm }}</span>
										<span class="sptpgm-article-header__tag">{{ mssSprtBiz.sprtBizCtpvNm }}</span>
										<span class="sptpgm-article-header__dday">D-{{ mssSprtBiz.dday }}</span>
									</header>
									<div class="sptpgm-article-body">
                    <a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank">
                      <h3 class="sptpgm-article-title">{{ mssSprtBiz.sprtBizNm }}</h3>
                      <p class="sptpgm-article-desc">{{ mssSprtBiz.sprtBizPurpose }}</p>
                    </a>
									</div>
									<footer class="sptpgm-article-footer">
										<dl class="sptpgm-article-dl">
											<dt><i class="icon-fp-date"></i><span class="sr-only">기간</span></dt>
											<dd>{{ mssSprtBiz.rcritBgngYmd }} ~ {{ mssSprtBiz.rcritEndYmd }}</dd>
										</dl>
                    <!--
										<dl class="sptpgm-article-dl">
											<dt><i class="icon-fp-phone"></i><span class="sr-only">전화</span></dt>
											<dd>02-302-1121</dd>
										</dl>
										-->
                    <a :href="`https://fanfandaero.kr/portal/preSprtBizPbancDetail.do?sprtBizCd=${mssSprtBiz.sprtBizCd}`" target="_blank" class="button-default is-primary is-rounded">신청하기</a>
									</footer>
								</article>
								<!-- //지원사업 아티클 -->
							</li>
						</ul>
						<div v-else class="no-results-found">
							<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
						</div>
						<!-- pagination -->
            <supt-biz-pagination page-name="supportBiz" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @pagingChange="mssPagingChange"></supt-biz-pagination>
            <!-- //pagination -->
					</div>
					<!-- e: 중소벤처기업부 판로 지원 사업 -->

					<!-- s: 지자체 판로 지원 사업 -->
					<div v-if="!showBizAside" class="support-section-biz">
						<div class="support-section-biz__form">
							<fieldset>
								<legend class="sr-only">상세 검색</legend>
								<div class="form-row">
									<div class="control-group">
										<div class="control">
											<select name="" id="" class="form-select is-small" v-model="area" v-on:change="searchAreaSupt">
												<option v-for="(area, idx) in areaList" v-text="area" :key="idx" :value="area"></option>
											</select>
										</div>
<!--										<button class="button-default is-primary is-rounded" @click="searchAreaSupt">검색</button>-->
									</div>
									<div class="control flex-1">
										<div class="checkbox-list">
											<div class="check">
												<input type="checkbox" value="all" id="chkAll" v-model="allSelected" v-on:change="searchAreaSupt"/>
												<label for="chkAll">전체</label>
											</div>
											<div class="check" v-for="(item, index) in checkList" :key="index">
												<input type="checkbox" :id="item" :value="item" v-model="selectList" v-on:change="searchAreaSupt"/>
												<label :for="item" :key="index + '1'"> {{ item }}</label>
											</div>
										</div>
										<div class="control-nums">
											{{ area }}
											<strong>{{ numberComma(totalCount) }}</strong>
											<span class="unit">건</span>
										</div>
									</div>
								</div>
							</fieldset>
						</div>
						<ul v-if="suptBizList.length > 0">
							<li v-for="(supt, idx) in suptBizList" :key="idx">
								<!-- 지원사업 아티클 -->
								<article class="sptpgm-article">
									<header class="sptpgm-article-header">
										<span class="sptpgm-article-header__tag">{{ supt.pldirSportRealmLclasCodeNm }}</span>
										<span class="sptpgm-article-header__tag" v-text="getAreaInfo(supt.pblancNm)"></span>
										<span class="sptpgm-article-header__dday" >{{ getDaysText(supt.reqstBeginDe, supt.reqstEndDe) }}</span> 
									</header>
									<div class="sptpgm-article-body">
										<h3 class="sptpgm-article-title">
											<a :href="`https://www.bizinfo.go.kr${supt.pblancUrl}`">{{ supt.pblancNm }}</a>
										</h3>
										<p class="sptpgm-article-desc" >{{ tagRemove(supt.bsnsSumryCn) }}</p>
									</div>
									<footer class="sptpgm-article-footer">
										<dl class="sptpgm-article-dl">
											<dt><i class="icon-fp-date"></i><span class="sr-only">기간</span></dt>
											<!-- <dd>{{ ymdToFormat(supt.reqstBeginDe, '-') }} ~ {{ ymdToFormat(supt.reqstEndDe, '-') }}</dd> -->
											<dd>{{ getFromToDayText(supt.reqstBeginDe, supt.reqstEndDe) }}</dd>
										</dl>
										<dl class="sptpgm-article-dl">
											<dt><i class="icon-fp-bldg"></i><span class="sr-only">센터</span></dt>
											<dd>{{ supt.exclnsttNm }}</dd>
										</dl>
										<a :href="`https://www.bizinfo.go.kr${supt.pblancUrl}`" class="button-default is-primary is-rounded" target="_blank" title="새창 열림">상세보기</a>
									</footer>
								</article>
								<!-- //지원사업 아티클 -->
							</li>
						</ul>
						<div v-else class="no-results-found">
							<p class="text">현재 모집중인 지원사업이 존재하지 않습니다.</p>
						</div>
						<!-- pagination -->
						<supt-biz-pagination page-name="supportBiz" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @pagingChange="pagingChange"></supt-biz-pagination>
						<!-- //pagination -->
					</div>
					<!-- e: 지자체 판로 지원 사업 -->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { ACT_GET_MSS_SPRT_BIZ_LIST, ACT_GET_SUPT_BIZ_LIST } from '@/store/_act_consts';
import { mapGetters } from 'vuex';
import MssSprtBizPagination from '@/components/support/MssSprtBizPagination.vue';
import SuptBizPagination from '@/components/support/SuptBizPagination.vue';
import {
	dateToDateFormat,
	getCheckItems,
	getItems,
	numberComma,
	setPaging, tagRemove,
	timestampToDateFormat,
	ymdToFormat
} from '@/assets/js/utils';

export default {
	name: 'supportBiz',
	components: {
    MssSprtBizPagination,
		SuptBizPagination,
	},
	data: () => ({
		totalCount: 100,
		pageSize: 20,
		pageListSize: 5,
		pageNo: 1,
		pagingMethodName: 'pagingChange',
    mssArea: '전국',
		area: '전국',
    sprtBizTyNm: '전체', // 중기부 사업 지원유형
		showBizAside: true, // 지원사업 안내 사이드 탭 보이기/갑추기
    mssAreaList: ['전국', '충북', '충남', '제주', '전북', '전남', '인천', '울산', '세종', '서울', '부산', '대전', '대구', '광주', '경북', '경남', '경기', '강원'],
		areaList: ['전국', '충북', '충남', '제주', '전북', '전남', '인천', '울산', '세종', '서울', '부산', '대전', '대구', '광주', '경북', '경남', '경기', '강원'],
		suptBizList: [],
		checkList: ['금융', '기술', '인력', '수출', '내수', '창업', '경영', '기타'],
    mssSelectList: [],
		selectList: [],
    panpanCtgrList: ['홍보·마케팅', '유통·판로', '교육·컨설팅', '인프라', '기타'],
    mssSprtBizList: [],  //중소벤처기업부 판로지원사업 목록
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		queries() {
			return {};
		},
    allMssSelected: {
      //getter
        get: function () {
          return this.panpanCtgrList.length === this.mssSelectList.length;
        },
        //setter
        set: function (e) {
          this.mssSelectList = e ? this.panpanCtgrList : [];
        },
    },
		allSelected: {
			//getter
			get: function () {
				return this.checkList.length === this.selectList.length;
			},
			//setter
			set: function (e) {
				this.selectList = e ? this.checkList : [];
			},
		},
	},
	watch: {},
	created() {

  },
	mounted() {
	  if(this.$route.query.showBizAside == 'false')
	    this.getSuptBiz();
    else
      this.showBiz();
  },
	methods: {
		tagRemove,
		numberComma,
		ymdToFormat,
		dateToDateFormat,
		timestampToDateFormat,
		showBiz() {
			this.showBizAside = true;
			this.pageNo = 1;
      this.showPanPan();
		},
    showPanPan() {
      this.$nextTick(() => {

        // api 호출
        this.$store
            .dispatch(`support/${ACT_GET_MSS_SPRT_BIZ_LIST}`, {
              pageNo: this.pageNo,
              pageSize: this.pageSize,
              //sprtBizCtpvNm: this.mssArea === '전국' ? '' : this.mssArea,
              sprtBizCtpvNm: '',
              sprtBizTyNm: this.mssSelectList.toString(),
            })
            .then((res) => {
              this.mssSprtBizList = getCheckItems(res);
              setPaging(this, res);
            })
            .catch((e) => {
              console.error(e);
            });
      });
    },
		getSuptBiz() {
			// 지자체 지원 사업 탭 클릭 시 해당 func 호출
			// 기존 showBiz(true) 처리를 여기 처리
			this.showBizAside = false;

			// 호출 페이로드
			const payload = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
				sprtBizArea: this.area === '전국' ? '' : this.getAreaName(this.area),
				sprtBizCodeNm: this.selectList.toString(),
			};
			// api 호출
			this.$store
				.dispatch(`support/${ACT_GET_SUPT_BIZ_LIST}`, payload)
				.then((res) => {
					if (this.suptBizList.length > 0) this.suptBizList.length = 0;
					this.suptBizList = this.suptBizList.concat(getItems(res));
					setPaging(this, res);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		getAreaInfo(pblancNm) {
			const areaList = ['충북', '충남', '제주', '전북', '전남', '인천', '울산', '세종', '서울', '부산', '대전', '대구', '광주', '경북', '경남', '경기', '강원'];
			let area = pblancNm.substr(1, 2);
			if (!areaList.includes(area)) area = '전국';
			return area;
		},
		getAreaName(areaNm) {
			switch (areaNm) {
				case "서울":
					return '서울특별시';
				case "경기":
					return '경기도';
				case "충북":
					return '충청북도';
				case "충남":
					return '충청남도';
				case "제주":
					return '제주특별자치도';
				case "전북":
					return '전라북도';
				case "전남":
					return '전라남도';
				case "인천":
					return '인천광역시';
				case "울산":
					return '울산광역시';
				case "세종":
					return '세종특별자치시';
				case "부산":
					return '부산광역시';
				case "대전":
					return '대전광역시';
				case "대구":
					return '대구광역시';
				case "광주":
					return '광주광역시';
				case "경북":
					return '경상북도';
				case "경남":
					return '경상남도';
				case "강원":
					return '강원특별자치도';
				default:
					return '';
			}
		},
		getDaysText(reqstBeginDe, reqstEndDe) {
			
			if (isNaN(reqstBeginDe) || isNaN(reqstEndDe) ) {
				return reqstBeginDe;
			}
			else {
			
				const reqEndDate = ymdToFormat(reqstEndDe, '-');
				const endDate = new Date(reqEndDate);
				const today = new Date();
				const diffDate = endDate - today;

				let remainedDays = Math.floor(diffDate / (1000 * 3600 * 24)) + 1;

				return remainedDays <= 0 ? (remainedDays === 0 ? 'D-Day' : `D+${remainedDays * (-1)}`) : `D-${remainedDays}`;
			}
		},
		getFromToDayText(reqstBeginDe, reqstEndDe){

			if (isNaN(reqstBeginDe) || isNaN(reqstEndDe) ) {
				return reqstBeginDe;
			}
			else {
			    return `${ymdToFormat(reqstBeginDe, '-')} ~ ${ymdToFormat(reqstEndDe, '-')}`
			}
		}, 
    mssPagingChange(no) {
      this.pageNo = no;
      this.showPanPan();
    },
		pagingChange(no) {
			this.pageNo = no;
      this.getSuptBiz();
		},
    searchMssSprt() {
      this.pageNo = 1;
      this.showPanPan();
    },
		searchAreaSupt() {
			this.pageNo = 1;
			this.getSuptBiz();
		},
	},
	destroyed() {},
};
</script>
